<script>
import { webAppURL } from "@/constants";

export default {
  name: "AppointmentInsurance",
  props: {
    insurance: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    appointment: {
      type: Object,
      required: true
    },
    withViewBenefitsLink: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    /**
     * @returns "inactive" | "unverified" | "not-accepted" | "active"
     */
    insuranceState() {
      const { insurance } = this;

      if (insurance.eligible === "no") {
        return "inactive";
      }

      if (insurance.eligible === "unknown") {
        if (insurance.acceptance_status === "do-not-accept") {
          return "not-accepted";
        }

        return "unverified";
      }

      if (insurance.eligible === "yes") {
        if (insurance.acceptance_status === "do-not-accept") {
          return "not-accepted";
        }

        const { appointment } = this;
        const checkInConfig =
          appointment.check_in && appointment.check_in.config
            ? appointment.check_in.config
            : {};
        const shouldReviewOutOfNetwork =
          !!checkInConfig["insurance:review_out_of_network_plans"] ||
          checkInConfig["insurance:review_out_of_network_plans"] === "yes";

        if (
          insurance.acceptance_status === "manual-review" &&
          shouldReviewOutOfNetwork
        ) {
          return "unverified";
        }
      }

      return "active";
    },
    insuranceUiStatus() {
      const statusesMap = {
        inactive: "inactive",
        "not-accepted": "not accepted",
        unverified: "unverified",
        active: "active"
      };

      return statusesMap[this.insuranceState];
    },
    insuranceIcon() {
      if (
        this.insuranceState === "inactive" ||
        this.insuranceState === "not-accepted"
      ) {
        return "icon-insurance-not-eligible";
      }

      return "icon-insurance-" + this.insurance.insurance_priority;
    },
    lastVerified() {
      if (!this.insurance.last_verified_datetime) {
        return "N/A";
      }

      return this.$options.filters.formatDate(
        this.insurance.last_verified_datetime,
        "MMM DD, hh:mm A"
      );
    },
    clearinghouseName() {
      const clearinghouseNames = {
        ecw: "ECW",
        availity: "Availity"
      };

      return clearinghouseNames[this.insurance.clearinghouse] || "N/A";
    },
    viewBenefitsLink() {
      if (!this.withViewBenefitsLink) {
        return null;
      }

      return `${webAppURL}/appointments/${this.appointment.id}/?benefit_check_id=${this.insurance.benefit_check_id}`;
    }
  }
};
</script>

<template>
  <div
    :class="{
      'root display-flex flex-column gap-s-ppNew': true,
      [insuranceState]: true
    }"
  >
    <div
      class="insurance-title display-flex justify-start align-center gap-2xs-ppNew"
    >
      <div
        :class="{
          'custom-icon': true,
          [insuranceIcon]: true
        }"
      ></div>
      <span class="title-text">{{
        title || insurance.insurance_priority + " insurance"
      }}</span>
      <div class="status">
        {{ insuranceUiStatus }}
      </div>
    </div>
    <div>
      <div class="text-m-semibold">
        {{ insurance.insurance_name }}
      </div>
      <div class="margin-top-2xs-ppNew">
        <div class="insurance-info-row">
          <span class="grey-100-ppNew">Eligibility check</span>
          <span class="black-100-ppNew">{{ lastVerified }}</span>
        </div>
        <div class="insurance-info-row margin-top-4xs-ppNew">
          <span class="grey-100-ppNew">Source</span>
          <span class="black-100-ppNew">{{ clearinghouseName }} </span>
        </div>
      </div>
    </div>
    <div
      v-if="!!viewBenefitsLink"
      class="display-flex justify-end align-center"
    >
      <a
        :href="viewBenefitsLink"
        target="_blank"
        class="text-m-semibold blue-100-ppNew"
        >View benefits</a
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/common.scss";

.root {
  border-radius: 8px;
  background-color: $grey-5;
  color: $black-100;
  padding: $gap-xs-ppNew;

  &.active {
    .status {
      border-color: $green-50;
      color: $green-120;
    }

    .icon-insurance-primary,
    .icon-insurance-secondary {
      color: $blue-100;
    }
  }

  &.not-accepted,
  &.inactive {
    background-color: $white;
    border: 2px solid $red-20;

    .status {
      color: $red-120;
      border-color: $red-50;
    }

    .icon-insurance-not-eligible {
      color: $red-100;
    }
  }

  &.unverified {
    .status {
      background-color: $grey-50;
      color: $white;
    }

    .icon-insurance-primary,
    .icon-insurance-secondary {
      color: $grey-50;
    }
  }
}

.label-text {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  white-space: nowrap;
}

.insurance-title {
  @extend .label-text;
  color: $grey-100;

  .title-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 160px;
  }
}

.custom-icon {
  display: inline-block;
  background-color: currentColor;

  &.icon-insurance-primary {
    width: 12px;
    height: 14px;
    mask: url("../assets/insurance-primary.svg") no-repeat center;
  }

  &.icon-insurance-secondary {
    width: 12px;
    height: 14px;
    mask: url("../assets/insurance-secondary.svg") no-repeat center;
  }

  &.icon-insurance-not-eligible {
    width: 14px;
    height: 14px;
    mask: url("../assets/insurance-not-eligible.svg") no-repeat center;
  }
}

.status {
  @extend .label-text;
  margin-left: auto;

  padding: 4px 6px;
  display: flex;
  align-items: center;
  border: 1px solid currentColor;
  border-radius: 6px;
}

.insurance-info-row {
  @extend .text-s-regular;

  display: flex;
  justify-content: space-between;
}
</style>
