var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'root display-flex flex-column gap-s-ppNew': true,
    [_vm.insuranceState]: true
  }},[_c('div',{staticClass:"insurance-title display-flex justify-start align-center gap-2xs-ppNew"},[_c('div',{class:{
        'custom-icon': true,
        [_vm.insuranceIcon]: true
      }}),_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.title || _vm.insurance.insurance_priority + " insurance"))]),_c('div',{staticClass:"status"},[_vm._v(" "+_vm._s(_vm.insuranceUiStatus)+" ")])]),_c('div',[_c('div',{staticClass:"text-m-semibold"},[_vm._v(" "+_vm._s(_vm.insurance.insurance_name)+" ")]),_c('div',{staticClass:"margin-top-2xs-ppNew"},[_c('div',{staticClass:"insurance-info-row"},[_c('span',{staticClass:"grey-100-ppNew"},[_vm._v("Eligibility check")]),_c('span',{staticClass:"black-100-ppNew"},[_vm._v(_vm._s(_vm.lastVerified))])]),_c('div',{staticClass:"insurance-info-row margin-top-4xs-ppNew"},[_c('span',{staticClass:"grey-100-ppNew"},[_vm._v("Source")]),_c('span',{staticClass:"black-100-ppNew"},[_vm._v(_vm._s(_vm.clearinghouseName)+" ")])])])]),(!!_vm.viewBenefitsLink)?_c('div',{staticClass:"display-flex justify-end align-center"},[_c('a',{staticClass:"text-m-semibold blue-100-ppNew",attrs:{"href":_vm.viewBenefitsLink,"target":"_blank"}},[_vm._v("View benefits")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }