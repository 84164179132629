<template>
  <!-- the "passes" function on the slot-scope only chains if the validation is successful -->
  <!-- Making it easier to call directly in the template than to call `passes` on the observer component -->
  <div class="modal-container">
    <div class="modal-fullscreen">
      <div class="modal-content">
        <ValidationObserver ref="observer1" v-slot="{ passes }">
          <div class="modal-header">
            <div class="modal-title" v-if="!isNew">
              Update Payment Plan
            </div>
            <div class="modal-title" v-if="isNew">
              Subscribe To The Payment Plan
            </div>
          </div>

          <div class="modal-body">
            <div class="block flex-spacing">
              <div class="u-text-bold">
                Current balance: {{ accountBalance | toUSD }}
              </div>
              <div v-if="!isNew">
                Already paid: {{ existingPlan.paid | toUSD }}
              </div>
              <div v-if="!isNew">
                Payments made:
                {{
                  existingPlan.payments_made >= 1
                    ? "Downpayment + " +
                      (existingPlan.payments_made - 1) +
                      " installments"
                    : 0
                }}
              </div>
            </div>
            <div class="block">
              <b-radio
                v-model="newPlan.type"
                name="paymentPlanType"
                native-value="standard"
                @input="updateTotal()"
              >
                Post-service payment plan
              </b-radio>
              <b-radio
                v-model="newPlan.type"
                name="paymentPlanType"
                native-value="upfront"
                @input="updateTotal()"
              >
                Pre-service payment plan
              </b-radio>
            </div>
            <b-field
              label="Location"
              v-if="showLocation"
              :message="{
                'The payment will be sent to the default bank account': !selectedLocation
              }"
            >
              <b-autocomplete
                :data="filteredLocations"
                ref="locationAutocomplete"
                placeholder="Start typing a location name..."
                :custom-formatter="formatLocation"
                v-model="defaultLocation"
                :open-on-focus="true"
                @select="option => (selectedLocation = option)"
                group-field="providerGroup"
                group-options="locations"
                @typing="searchLocations"
                :loading="isLocationFetching"
              >
              </b-autocomplete>
            </b-field>
            <b-field label="Payment method">
              <b-autocomplete
                :data="patientCards"
                ref="cardAutocomplete"
                placeholder="Select Patient Card"
                :custom-formatter="formatCard"
                :open-on-focus="true"
                @select="option => (newPlan.default_payment_method = option.id)"
              >
                <template slot-scope="props">
                  <div class="card-template">
                    <span>{{ props.option.name }}</span>
                    <span v-if="props.option.card_type === 'bank_account'" class=""
                    >•••• {{ props.option.bank_account_mask }}</span
                    >
                    <span v-if="props.option.card_type !== 'bank_account'" class=""
                    ><i
                        :class="'card-icon card-' + props.option.cardIconClass"
                    />
                      •••• {{ props.option.last4 }}</span
                    >
                    <span class="" v-if="props.option.card_type !== 'bank_account'">
                      <span class="">Exp</span>
                      {{ props.option.exp_month }}/{{
                        props.option.exp_year.toString().substr(2)
                      }}
                    </span>
                    <span v-if="props.option.auto_charge_enabled">
                      <i
                        class="fa fa-credit-card fa-success"
                        uib-tooltip="Auto charge consent given"
                        tooltip-class="tooltip-wide"
                      />
                    </span>
                    <span v-if="!props.option.auto_charge_enabled">
                      <i
                        class="fa fa-credit-card fa-danger"
                        uib-tooltip="No auto charge consent"
                        tooltip-class="tooltip-wide"
                      />
                    </span>
                    <span class="is-size-7"
                      >Added:
                      {{ props.option.created_at | formatDate("l") }}
                    </span>
                    <span class="is-size-7">{{ props.option.status }}</span>
                  </div>
                </template>
              </b-autocomplete>
              <p class="addon-right">
                <b-button class="button is-primary" @click="addCard()">
                  Add New Card
                </b-button>
              </p>
            </b-field>
            <b-field label="Set payment date" v-if="!isNew">
              <b-datepicker
                placeholder="Click to select..."
                v-model="newPlan.next_payment"
              />
            </b-field>
            <b-field label="Expected total payment">
              <b-input
                placeholder="0.00"
                type="number"
                v-bind:readonly="newPlan.type === 'standard'"
                v-model.number="newPlan.total_amount"
                @change.native="debounce(updateTotal)"
                min="0.5"
                step="0.01"
              />
            </b-field>

            <b-field label="Downpayment">
              <b-input
                placeholder="0.00"
                type="number"
                :readonly="!isNew"
                v-model.number="newPlan.downpayment"
                @change.native="debounce(updateTotal)"
                min="0.5"
                step="0.01"
                required="true"
              />
            </b-field>
            <b-field label="Installment">
              <b-input
                placeholder="0.00"
                type="number"
                :aria-disabled="!isNew"
                v-model.number="newPlan.installment"
                @change.native="debounce(updateTotal)"
                min="0.5"
                step="0.01"
                required="true"
              />
              <p class="addon-right">
                <b-button class="button is-primary" @click="autoInstallment()">
                  Calculate
                </b-button>
              </p>
            </b-field>

            <b-field label="Number of installments left">
              <b-input
                placeholder="0.00"
                type="number"
                :aria-disabled="!isNew"
                v-model.number="newPlan.new_installments"
                @change.native="debounce(updateTotal)"
                min="1"
                step="1"
                required="true"
              />
              <p class="addon-right">
                <b-button class="button is-primary" @click="autoPayments()">
                  Calculate
                </b-button>
              </p>
            </b-field>

            <div class="block has-text-danger" v-if="invalid">
              <i class="fa fa-exclamation-triangle" />
              Payment plan parameters does not match current balance!
            </div>
            <div class="block flex-spacing">
              <div>
                Expected remaining: ${{ newPlan.total_amount - newPlan.paid }}
              </div>
              <div>Last installment: ${{ lastInstallment }}</div>
            </div>
          </div>

          <div class="modal-footer">
            <div
              v-if="error"
              class="block has-text-danger"
              v-html="error"
            ></div>
            <div class="buttons">
              <button
                class="button is-primary"
                v-if="isNew"
                @click="passes(selectContact)"
              >
                Request consent
              </button>
              <button class="button is-primary" @click="passes(submit)">
                {{ isNew ? "Subscribe" : "Update" }}
              </button>
              <button class="button is-danger" @click="cancel()">
                Cancel
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faSignInAlt);

import { mapActions, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import BButton from "buefy/src/components/button/Button";
import BField from "buefy/src/components/field/Field";
import BInput from "buefy/src/components/input/Input";
import BRadio from "buefy/src/components/radio/Radio";
import BDatepicker from "buefy/src/components/datepicker/Datepicker";
import BAutocomplete from "buefy/src/components/autocomplete/Autocomplete";
import { debounce, clone, isUndefined, each } from "lodash";
import toast from "../mixins/toast";
import _find from "lodash/find";
import _orderBy from "lodash/orderBy";
import _debounce from "lodash/debounce";

export default {
  name: "PaymentPlan",
  components: {
    ValidationObserver,
    BButton,
    BField,
    BInput,
    BRadio,
    BDatepicker,
    BAutocomplete
  },
  mixins: [toast],
  mounted() {
    this.patientCards.forEach(c => {
      if (this.newPlan && this.newPlan.default_payment_method == c.id) {
        this.$refs.cardAutocomplete.setSelected(c);
      }
    });
    this.getLocations();
  },
  data() {
    return {
      error: null,
      lastInstallment: 0,
      newPlan: {
        next_payment: new Date(),
        type: "standard"
      },
      submitted: false,
      currentBalance: 0,
      invalid: false,
      selectedCard: null,
      selectedLocation: null,
      isLocationFetching: false,
      defaultLocation: null,
      showLocation: false
    };
  },
  computed: {
    ...mapState({
      filteredLocations(state) {
        this.isLocationFetching = false;
        if (!state.locations) return;
        const groups = state.locations.reduce((acc, location) => {
          const pgName =
            location.data.provider_group_name || "Without provider group";
          if (!acc[pgName]) {
            acc[pgName] = [];
          }
          acc[pgName].push(location);
          return acc;
        }, {});
        if (!this.defaultLocation) {
          this.selectedLocation = _find(state.locations, {
            id: state.user.default_payment_location_id + ""
          });
          this.defaultLocation = this.formatLocation(this.selectedLocation);
        }
        return _orderBy(
          Object.keys(groups).map(pgName => {
            return { providerGroup: pgName, locations: groups[pgName] };
          }),
          "providerGroup"
        );
      },
      patient: state => state.patient,
      accountBalance: state => {
        if (state.patient.family_group_role === "guarantor") {
          return state.patient.family_balance;
        }
        return state.patient.current_balance;
      },
      existingPlan: state => {
        let plans = (state.patient.payment_plans || []).filter(
          x =>
            x.status === "declined" ||
            x.status === "subscribed" ||
            x.status === "not_subscribed"
        );
        if (plans.length > 0) {
          return plans[0];
        } else {
          return null;
        }
      },
      patientCards: state => state.patientCards
    }),
    isNew() {
      return !(this.existingPlan && this.existingPlan.id);
    }
  },
  created() {
    this.debounce = f => {
      return debounce(f, 500);
    };

    let self = this;
    this.loadCards().then(() => {
      self.patientCards.forEach(c => {
        if (self.newPlan && self.newPlan.default_payment_method == c.id) {
          self.$refs.cardAutocomplete.setSelected(c);
        }
      });
    });
    if (this.isNew) {
      this.newPlan = {
        n_installments: 1,
        paid: 0,
        next_payment: new Date()
      };
    } else {
      this.newPlan = clone(this.existingPlan);
    }
    this.currentBalance =
      this.newPlan.type === "upfront"
        ? this.newPlan.total_amount
        : this.accountBalance;
    this.newPlan.payments_made = this.newPlan.payments_made || 0;
    this.newPlan.new_installments =
      this.newPlan.n_installments - this.newPlan.payments_made;
    this.newPlan.paid =
      (this.newPlan.total_amount || 0) - (this.newPlan.remaining || 0);
    this.newPlan.type =
      this.newPlan.type === "upfront" || this.currentBalance <= 0
        ? "upfront"
        : "standard";
    if (this.newPlan.type === "standard") {
      this.newPlan.total_amount = this.currentBalance;
    } else {
      this.newPlan.total_amount = Math.max(0, this.currentBalance);
    }

    this.updateTotal();
  },
  methods: {
    ...mapActions([
      "showEditPlanDlg",
      "showAddCardDlg",
      "updatePaymentPlan",
      "showRequestConsentDlg",
      "loadCards",
      "requestConsent",
      "getLocations"
    ]),
    formatLocation(l) {
      if (!l) return;
      return "#" + l.external_id + " " + l.name;
    },
    needToCoverBalance() {
      // Method calculates amount which should be covered by further installment payments.
      if (this.newPlan.type === "upfront") {
        return (
          this.newPlan.total_amount -
          (this.isNew ? this.newPlan.downpayment : this.newPlan.paid)
        );
      } else {
        return (
          this.currentBalance - (this.isNew ? this.newPlan.downpayment : 0)
        );
      }
    },
    autoInstallment() {
      this.newPlan.new_installments = Math.max(
        this.newPlan.new_installments,
        1
      );
      this.newPlan.installment =
        Math.ceil(
          (100 * this.needToCoverBalance()) / this.newPlan.new_installments
        ) / 100;
      this.updateTotal();
    },
    autoPayments() {
      this.newPlan.new_installments = Math.ceil(
        this.needToCoverBalance() / this.newPlan.installment
      );
      this.updateTotal();
    },
    async submit() {
      this.submitted = true;
      this.newPlan.amount = this.newPlan.downpayment; // legacy API stuff
      let planData = { ...this.newPlan, patientId: this.patient.id };
      if (this.showLocation && this.selectedLocation) {
        planData.location_id = parseInt(this.selectedLocation.id);
      }
      this.updatePaymentPlan(planData)
        .then(() => {
          this.showEditPlanDlg(false);
          this.newPlan = { n_installments: 1, paid: 0 };
          this.$toast.success(
            this.isNew ? "Subscribed for plan." : "Plan was updated."
          );
        })
        .catch(this.handleAPIError);
    },
    cancel() {
      this.showEditPlanDlg(false);
      this.newPlan = {
        n_installments: 1,
        paid: 0
      };
    },
    selectContact() {
      this.showRequestConsentDlg(true)
        .then(contact => {
          if (contact === null) {
            return;
          }
          this.newPlan.amount = this.newPlan.downpayment; // legacy API stuff
          this.requestConsent({ planData: this.newPlan, contact }).then(() => {
            this.showEditPlanDlg(false);
            this.$toast.success("Request sent.");
          });
        })
        .catch(this.handleAPIError);
    },
    handleAPIError(err) {
      this.$toast.error(err.error, true);
      if (err.errors) {
        this.error = "";
        each(err.errors, (v, k) => {
          if (k === "_schema") return;
          this.error += k + ": " + v + "<br/>";
        });
      }
    },
    fmtNum(x) {
      return isUndefined(x) ? x : parseFloat(x.toFixed(2));
    },

    updateTotal() {
      // computed property does not detect changes properly
      this.showLocation =
        this.isNew && this.newPlan && this.newPlan.type === "upfront";
      this.invalid = false;
      this.newPlan.installment = this.fmtNum(this.newPlan.installment);
      this.newPlan.downpayment = this.fmtNum(this.newPlan.downpayment);
      this.newPlan.n_installments =
        (this.newPlan.payments_made || 1) +
        parseInt(this.newPlan.new_installments);

      if (
        !this.newPlan.installment ||
        !this.newPlan.downpayment ||
        !this.newPlan.n_installments
      ) {
        this.invalid = true;
        this.lastInstallment = this.newPlan.installment;
        return;
      }

      if (this.newPlan.type === "standard") {
        // Payment plan capped with current patient balance
        this.currentBalance = this.accountBalance;
        if (this.isNew) {
          this.newPlan.total_amount = this.fmtNum(
            this.newPlan.downpayment +
              (this.newPlan.n_installments - 1) * this.newPlan.installment
          );
        } else {
          this.newPlan.total_amount = this.fmtNum(
            this.newPlan.paid +
              (this.newPlan.n_installments - this.newPlan.payments_made) *
                this.newPlan.installment
          );
        }
        if (this.newPlan.total_amount < this.currentBalance) {
          this.invalid = true;
          this.lastInstallment = this.newPlan.installment;
          return;
        }
        this.lastInstallment = this.fmtNum(
          this.newPlan.installment -
            (this.newPlan.total_amount -
              this.newPlan.paid -
              this.currentBalance)
        );

        this.newPlan.total_amount = this.fmtNum(
          this.newPlan.total_amount -
            this.newPlan.installment +
            this.lastInstallment
        );
      } else {
        // Upfront payment plan
        let paymentsMade = 1;
        let down = this.newPlan.downpayment;
        if (!this.isNew) {
          down = 0;
          paymentsMade = this.newPlan.payments_made;
        }

        this.lastInstallment = this.fmtNum(
          this.newPlan.total_amount -
            this.newPlan.paid -
            this.newPlan.installment *
              (this.newPlan.n_installments - paymentsMade - 1) -
            down
        );
      }
      if (
        this.lastInstallment > this.newPlan.installment ||
        this.lastInstallment <= 0
      ) {
        this.lastInstallment = this.newPlan.installment;
        this.invalid = true;
      }
    },
    addCard() {
      this.showAddCardDlg(true);
    },
    formatCard(c) {
      if (c.card_type === 'bank_account') {
        return c.name + " •••• " + c.bank_account_mask;
      }

      return (
        c.name +
        "   •••• " +
        c.last4 +
        " [" +
        c.exp_month +
        "/" +
        c.exp_year.toString().substr(2) +
        "] " +
        c.status
      );
    },
    searchLocations: _debounce(function(name) {
      this.isLocationFetching = true;
      this.selectedLocation = null;
      this.getLocations(name);
    }, 500)
  }
};
</script>

<style scoped lang="scss"></style>
