<template>
  <div class="chat-body">
    <div class="message-list" ref="messageContainer">
      <div class="has-text-grey" v-if="!messages || messages.length === 0">
        No messages yet.
      </div>
      <div
        v-for="m in messages"
        class="chat-message"
        :key="m.id"
        :class="{ 'chat-message--inbound': m.inbound }"
      >
        <div class="chat-message-info">
          <span class="">{{ m.type }}</span>
          <span class="">{{ m.created_at | formatDate("l LT") }}</span>
        </div>
        <div class="chat-message-body" v-html="m.text" />
      </div>
    </div>
    <form class="compose-message"  v-if="!currentUserIsReadOnly" @submit="send()">
      <div class="current-status" v-if="billingCycle.paused !== undefined">
        Billing cycle is
        {{ billingCycle.paused ? "paused by chat!" : "active." }}
      </div>
      <b-field>
        <b-input
          placeholder="Type your message here..."
          type="textarea"
          v-model="newMessageText"
        />
      </b-field>
      <div class="buttons">
        <b-button class="button" @click="send()">
          Send
        </b-button>
        <b-button
          class="button"
          @click="sendAndResume()"
          :disabled="!billingCycle.paused"
        >
          {{ newMessageText ? "Send & " : "" }}Resume Billing
        </b-button>
      </div>
    </form>
    <div v-if="error" class="has-text-danger">
      {{ error }}
    </div>
    <div v-if="currentUserIsReadOnly" class="has-text-gray margin-top-2xs-ppNew">
      You don't have permission to send messages.
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BField from "buefy/src/components/field/Field";

export default {
  name: "PatientMessages",
  components: { BField },
  mounted() {
    this.getMessages(this.patient.id);
  },
  data() {
    return {
      newMessageText: null,
      error: null,
      messagesUpdated: false
    };
  },
  computed: mapState({
    messages: state => state.messages,
    patient: state => state.patient,
    currentUserIsReadOnly: state => state.is_readonly_user,
    billingCycle: state => state.patient.last_billing_cycle || {}
  }),

  created() {},
  methods: {
    ...mapActions(["getMessages", "sendMessage", "closeThread"]),
    send() {
      this.error = null;
      return this.sendMessage({
        patientId: this.patient.id,
        text: this.newMessageText
      }).then(
        () => {
          this.newMessageText = null;
        },
        err => {
          this.error = err.error;
        }
      );
    },
    sendAndResume() {
      if (this.newMessageText) {
        this.send().then(() => {
          this.closeThread();
        });
      } else {
        this.closeThread();
      }
    }
  },
  updated() {
    if (this.messagesUpdated) {
      var container = this.$refs.messageContainer;
      container.scrollTop = container.scrollHeight;
      this.messagesUpdated = false;
    }
  },
  watch: {
    messages() {
      this.messagesUpdated = true;
    }
  }
};
</script>

<style scoped lang="scss">
.message-list {
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  overflow-y: auto;

  .chat-message {
    margin-top: 0.5rem;
    max-width: 94%;
    align-self: flex-end;

    .chat-message-body {
      background: $gray-4;
      padding: 1rem;
      border-radius: 0.25em;
      word-break: break-word;
      white-space: pre-line;
      max-height: 320px;
      overflow-y: auto;
    }

    .chat-message-info {
      color: $gray-1;
      text-transform: uppercase;
      font-size: 0.8em;
      margin-bottom: 0.125em;
      > * {
        margin-right: 1em;
      }
    }

    &--inbound {
      align-self: flex-start;
      .chat-message-body {
        background: $primary;
        color: $white;
      }
    }
  }
}
.chat-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.compose-message {
  width: 100%;
  padding: 0.25rem 0;
  .current-status {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
  }
}
</style>
