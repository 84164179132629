<script>
import { mapActions, mapState } from "vuex";
import PosPaymentForm from "./PosPaymentForm.vue";
import PosPaymentState from "./PosPaymentState.vue";

export default {
  name: "PosPaymentDialog",
  components: {
    PosPaymentForm,
    PosPaymentState
  },
  data() {
    return {
      // 'form' | 'payment'
      step: "form",
      workflowResponse: null,
      isCancellingPosWorkflow: false,
      isWorkflowCancelled: false,
      isFetchingActiveWorkflow: false
    };
  },
  computed: {
    ...mapState({
      externalPatientId: state => state.patientId,
      isPosDialogShown: state => state.dialogOpen.posPayment,
      paymentStep() {
        if (
          !this.workflowResponse ||
          !this.workflowResponse.workflow_steps ||
          !this.workflowResponse.workflow_steps.length
        ) {
          return null;
        }

        return this.workflowResponse.workflow_steps.find(
          i => i.name === "pay-and-store-card"
        );
      },
      isPaymentPending() {
        return (
          this.paymentStep &&
          (this.paymentStep.status === "pending" ||
            this.paymentStep.status === "created")
        );
      },
      hasSuccessfulPayment() {
        return this.paymentStep && this.paymentStep.status === "success";
      }
    })
  },
  methods: {
    ...mapActions([
      "showPosPaymentDialog",
      "getPosPaymentStatus",
      "getPatient",
      "cancelPosWorkflow",
      "getActivePosWorkflow"
    ]),
    async handlePaymentStart({ terminalId, posPaymentResponse }) {
      this.step = "payment";

      this.handlePosPayment(posPaymentResponse, terminalId);
    },
    async handlePosPayment(response, terminalId) {
      const { id, workflow_status } = response || {};
      this.workflowResponse = response;

      if (
        !this.isPosDialogShown ||
        (workflow_status !== "created" && workflow_status !== "pending")
      ) {
        return;
      }

      const resp = await this.getPosPaymentStatus({
        terminalId: terminalId,
        workflowId: id
      });

      setTimeout(() => {
        this.handlePosPayment(resp, terminalId);
      }, 2000);
    },
    closeDialog() {
      if (this.step === "payment") {
        this.getPatient({ patientExternalId: this.externalPatientId });
      }

      this.showPosPaymentDialog(false);
    },
    async cancelWorkflow() {
      if (this.isCancellingPosWorkflow) return;

      const isSuccessful =
        this.workflowResponse &&
        this.workflowResponse.workflow_status === "success";

      if (
        isSuccessful ||
        this.isWorkflowCancelled ||
        !this.workflowResponse ||
        this.step === "form"
      ) {
        this.closeDialog();
        return;
      }

      this.isWorkflowCancelled = false;
      this.isCancellingPosWorkflow = true;

      try {
        await this.cancelPosWorkflow({
          terminalId: this.workflowResponse.pos_terminal_id,
          workflowId: this.workflowResponse.id
        });

        this.isWorkflowCancelled = true;

        await new Promise(res => setTimeout(res, 1000));

        this.closeDialog();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isCancellingPosWorkflow = false;
      }
    }
  },
  async created() {
    try {
      this.isFetchingActiveWorkflow = true;

      const activeWorkflow = await this.getActivePosWorkflow();

      if (!activeWorkflow || !activeWorkflow.pos_terminal_id) return;

      this.handlePaymentStart({
        posPaymentResponse: activeWorkflow,
        terminalId: activeWorkflow.pos_terminal_id
      });
    } catch (error) {
      if (error.response && error.response.status === 404) return;
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      this.isFetchingActiveWorkflow = false;
    }
  }
};
</script>

<template>
  <div :class="{ wrapper: true, [step]: true }">
    <div class="base">
      <div class="close-icon-wrapper" @click="cancelWorkflow">
        <font-awesome-icon icon="times" size="1x"></font-awesome-icon>
      </div>

      <div
        v-if="isFetchingActiveWorkflow"
        class="display-flex align-center gap-2xs-ppNew loadingContainer"
      >
        <font-awesome-icon
          icon="circle-notch"
          spin
          size="2x"
          class="blue-100-ppNew"
        ></font-awesome-icon>
        <div class="text-l-medium">Checking for pending payments...</div>
      </div>

      <template v-else>
        <PosPaymentForm
          v-if="step === 'form'"
          @posWorkflowStarted="handlePaymentStart"
        />

        <PosPaymentState
          v-if="step === 'payment'"
          :workflowResponse="workflowResponse"
          @cancelWorkflow="cancelWorkflow"
          :isCancellingPosWorkflow="isCancellingPosWorkflow"
          :isWorkflowCancelled="isWorkflowCancelled"
          :isPaymentPending="isPaymentPending"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loadingContainer {
  flex: 1 0 auto;
  margin: 0 auto;
}

.wrapper {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  animation: fadeIn 0.7s 0.25s forwards;
  opacity: 0;
}

.base {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px;
  width: 640px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  transition: min-height 0.5s ease, width 0.5s ease;

  .payment & {
    width: 520px;
    min-height: 360px;
  }
}

.close-icon-wrapper {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $grey-100;
  background: $grey-5;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: $blue-100;
    background: $grey-10;
  }
}
</style>
