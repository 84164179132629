var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stateWrapper",class:{ contentChanged: _vm.hasContentChanged }},[(_vm.content)?_c('div',[_c('div',{staticClass:"display-flex flex-column gap-s-ppNew"},[_c('div',{staticClass:"display-flex align-center gap-xs-ppNew"},[_c('font-awesome-icon',{class:{
            'grey-100-ppNew': true,
            [_vm.content.iconColorClass]: !!_vm.content.iconColorClass,
            animateIcon: _vm.content.icon === 'check-circle'
          },attrs:{"icon":_vm.content.icon,"spin":_vm.content.icon === 'circle-notch',"size":"2x"}}),_c('div',[_c('div',{staticClass:"display-flex gap-3xs-ppNew text-m-semibold grey-100-ppNew"},[(_vm.totalAmount)?_c('div',[_vm._v(_vm._s(_vm._f("toUSD")(_vm.totalAmount)))]):_vm._e(),(_vm.totalAmount)?_c('div',[_vm._v("-")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.content.heading))])]),(_vm.content.description)?_c('div',{staticClass:"text-m-medium grey-70-ppNew description"},[_vm._v(" "+_vm._s(_vm.content.description)+" ")]):_vm._e()])],1)])]):_c('div',[_c('div',[_vm._v("Error. No response receieved")])]),_c('div',{staticClass:"controls display-flex flex-column align-right gap-3xs-ppNew"},[_c('div',{staticClass:"margin-left-auto-ppNew display-flex align-center gap-xs-ppNew"},[(_vm.cancelButtonText)?_c('button',{class:{
          'text-m': true,
          'button cancel-button': true,
          cancelling: _vm.isCancellingPosWorkflow
        },on:{"click":_vm.onCancelClick}},[(_vm.isCancellingPosWorkflow)?_c('span',{staticClass:"loader"}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.cancelButtonText))])]):_vm._e(),(_vm.canPrintReceipt)?_c('button',{staticClass:"button text-m print-button",attrs:{"type":"button","disabled":_vm.isFetchingReceipt},on:{"click":_vm.getReceipt}},[(_vm.isFetchingReceipt)?_c('span',{staticClass:"loader"}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.isFetchingReceipt ? "Getting Receipt..." : "Get Receipt"))])]):_vm._e()]),_c('div',{staticClass:"text-s red-120-ppNew"},[_vm._v(_vm._s(_vm.receiptError))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }