<script>
import AppointmentInsurance from "./AppointmentInsurance.vue";

export default {
  name: "AppointmentInsurancesList",
  components: {
    AppointmentInsurance
  },
  props: {
    appointment: {
      type: Object,
      required: true
    },
    withViewBenefitsLink: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isInsurancesListVisible: false
    };
  },
  computed: {
    insurances() {
      const { appointment } = this;

      return appointment.check_in &&
        appointment.check_in.insurances &&
        appointment.check_in.insurances.list
        ? appointment.check_in.insurances.list.sort(a => {
            if (a.insurance_priority === "primary") {
              return -1;
            }

            return 1;
          })
        : [];
    },
    areAllInsurancesEligible() {
      const { appointment } = this;
      const checkInConfig =
        appointment.check_in && appointment.check_in.config
          ? appointment.check_in.config
          : {};
      const shouldReviewOutOfNetwork =
        !!checkInConfig["insurance:review_out_of_network_plans"] ||
        checkInConfig["insurance:review_out_of_network_plans"] === "yes";

      return this.insurances.every(insurance => {
        const isNotAccepted = insurance.acceptance_status === "do-not-accept";
        const isUnverified =
          insurance.acceptance_status === "manual-review" &&
          shouldReviewOutOfNetwork;

        return insurance.eligible === "yes" && !isNotAccepted && !isUnverified;
      });
    }
  },
  mounted() {
    this.isInsurancesListVisible = this.insurances.length <= 1;
  },
  watch: {
    appointment(newAppointment, oldAppointment) {
      const newAppointmentInsurances =
        newAppointment.check_in &&
        newAppointment.check_in.insurances &&
        newAppointment.check_in.insurances.list
          ? newAppointment.check_in.insurances.list
          : [];
      const oldAppointmentInsurances =
        oldAppointment.check_in &&
        oldAppointment.check_in.insurances &&
        oldAppointment.check_in.insurances.list
          ? oldAppointment.check_in.insurances.list
          : [];

      if (
        newAppointment.id === oldAppointment.id &&
        newAppointmentInsurances.length === oldAppointmentInsurances.length
      ) {
        return;
      }

      this.isInsurancesListVisible = newAppointmentInsurances.length <= 1;
    }
  },
  methods: {
    toggleInsurancesVisibility() {
      this.isInsurancesListVisible = !this.isInsurancesListVisible;
    }
  }
};
</script>

<template>
  <div class="root">
    <div
      v-if="this.insurances.length === 0"
      class="no-insurances bg-grey-5-ppNew padding-xs-ppNew display-flex justify-start align-center gap-2xs-ppNew"
    >
      <div class="insurance-icon"></div>
      <span class="grey-100-ppNew text-m-regular">No insurances on file</span>
    </div>
    <button
      v-if="this.insurances.length > 1"
      type="button"
      :class="{ toggleBtn: true, opened: isInsurancesListVisible }"
      @click="toggleInsurancesVisibility"
    >
      <div class="display-flex align-center gap-2xs-ppNew">
        <div class="toggle-icon"></div>
        Insurances on file
      </div>
      <span
        :class="{
          badge: true,
          success: areAllInsurancesEligible,
          error: !areAllInsurancesEligible
        }"
        >{{ this.insurances.length }}</span
      >
    </button>
    <div v-if="isInsurancesListVisible">
      <div
        class="margin-top-xs-ppNew"
        v-for="(item, index) in insurances"
        :key="index"
      >
        <AppointmentInsurance
          :insurance="item"
          :title="insurances.length === 1 ? 'insurance policy' : undefined"
          :appointment="appointment"
          :withViewBenefitsLink="withViewBenefitsLink"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/common.scss";

.insurances-visibility-toggle {
  color: $blue-100;
}

.no-insurances {
  border-radius: 8px;
}

.toggleBtn {
  @extend .text-m-semibold;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  border: none;
  border-radius: 8px;
  background: $grey-5;
  color: $blue-100;
  cursor: pointer;
  padding: $gap-xs-ppNew;

  .toggle-icon {
    mask: url("../assets/caret.svg") no-repeat center;
    background-color: $blue-100;
    width: 16px;
    height: 16px;
    transition: transform 0.2s ease;
    transform: rotate(180deg);
  }

  &:hover {
    background-color: $blue-10;
  }

  &.opened {
    background-color: $blue-20;
    color: $blue-180;

    .toggle-icon {
      transform: rotate(270deg);
      background-color: $blue-180;
    }
  }
}

.insurance-icon {
  display: inline-block;
  background-color: currentColor;
  mask: url("../assets/insurance-primary.svg") no-repeat center;
  color: $grey-50;
  width: 12px;
  height: 14px;
}

.badge {
  @extend .text-s-semibold;

  display: block;
  color: $white;
  border-radius: 24px;
  padding: $gap-4xs-ppNew $gap-2xs-ppNew;

  &.success {
    background-color: $green-100;
  }

  &.error {
    background-color: $red-100;
  }
}
</style>
